









import Vue from "vue";
import coreApiClient from "@/services/apis/coreApiClient";
import warehouseImportPaymentStatuses from "@/constants/purchaseCommandPaymentStatuses";
import makeSelectOptions from "@common/util/makeSelectOptions";
import userManager from "@/services/userManager";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";

function makeBaseItemProperties(country, createMany = false) {
  return {
    orderId: {
      attrs: {
        label: "Mã phiếu nhập kho",
      },
    },
    purchaseCommandId: {
      type: "XAutocomplete",
      attrs: {
        label: "ID mua hàng",
        required: true,
        "item-value": "_id",
        "item-text": "orderIdAlt",
        xOptions: {
          content: {
            async itemsSuggestor(search, value, limit) {
              const { items } = await coreApiClient.call("purchaseCommands", "findAll", {
                payload: JSON.stringify({
                  search,
                  limit,
                  filters: [
                    {
                      key: "countryId",
                      operator: "equal_to",
                      value: country._id,
                    },
                    {
                      key: "_id",
                      operator: "equal_to",
                      value,
                    },
                  ],
                }),
              });
              items.forEach((item) => {
                if (!item.orderIdAlt) {
                  item.orderIdAlt = "[Empty]";
                }
              });
              return items;
            },
          },
        },
      },
      ext: {
        condition() {
          return !createMany;
        },
      },
    },
    warehouseId: {
      type: "XAutocomplete",
      attrs: {
        label: "Kho",
        required: true,
        "item-value": "_id",
        "item-text": "name",
        xOptions: {
          content: {
            async itemsLoader() {
              const { items } = await coreApiClient.call("warehouses", "findAll", {
                payload: JSON.stringify({
                  limit: -1,
                  filters: [
                    {
                      key: "countryId",
                      operator: "equal_to",
                      value: country._id,
                    },
                  ],
                }),
              });
              return items;
            },
          },
        },
      },
      ext: {
        condition() {
          return !createMany;
        },
      },
    },
    productId: {
      type: "XAutocomplete",
      attrs: {
        label: "Sản phẩm",
        required: true,
        "item-value": "_id",
        "item-text": "sku",
        xOptions: {
          content: {
            async itemsSuggestor(search, value, limit) {
              const { items } = await coreApiClient.call("products", "findAll", {
                payload: JSON.stringify({
                  search,
                  limit,
                  filters: [
                    {
                      key: "countryId",
                      operator: "equal_to",
                      value: country._id,
                    },
                    {
                      key: "_id",
                      operator: "equal_to",
                      value,
                    },
                  ],
                }),
              });
              return items;
            },
          },
        },
      },
    },
    count: {
      type: "number",
      attrs: {
        label: "Số lượng",
        required: true,
      },
    },
    trackingNumber: {
      attrs: {
        label: "Tracking number",
        required: true,
      },
    },
    note: {
      attrs: {
        label: "Ghi chú",
      },
    },
    description: {
      attrs: {
        label: "Diễn giải",
      },
    },
    manufactureTime: {
      type: "XDateTimePicker",
      attrs: {
        label: "Ngày sản xuất",
      },
    },
    expirationTime: {
      type: "XDateTimePicker",
      attrs: {
        label: "Hạn sử dụng",
      },
    },
  };
}

export default Vue.extend({
  data() {
    return {
      self: this,
      tableOptions: null,
      importItems: [],
      insertDialogContainer: new DataContainer(),
      userManager,
    };
  },
  methods: {
    makeTableOptions(country) {
      const self = this;
      const self2 = this;
      return {
        attrs: {
          "item-key": "_id",
          "sort-by": "createdTime",
          "sort-desc": true,
          "show-select": true,
        },
        content: {
          name: "Quản lý nhập kho",
          urlEnabled: true,
          search: {},
          displayFields: {
            _id: {
              text: "ID",
              sortable: true,
            },
            createdTime: {
              text: "Ngày tạo",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            deliveriedTime: {
              text: "Ngày nhập kho",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            orderId: {
              text: "Mã phiếu nhập kho",
              sortable: true,
            },
            purchaseCommandOrderId: {
              text: "Mã ID Order",
              sortable: true,
              options: {
                subProp: "purchaseCommand.orderId",
              },
            },
            purchaseCommandOrderIdAlt: {
              text: "ID mua hàng",
              sortable: true,
              options: {
                subProp: "purchaseCommand.orderIdAlt",
              },
            },
            description: {
              text: "Diễn giải",
            },
            product: {
              text: "Sản phẩm",
              sortable: true,
              options: {
                subProp: "product.sku",
              },
            },
            count: {
              text: "Số lượng",
              sortable: true,
              options: {
                filter: "number2",
              },
            },
            cbmPerKg: {
              text: "CMB/KG",
              sortable: true,
            },
            manufactureTime: {
              text: "Ngày sản xuất",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            expirationTime: {
              text: "Hạn sử dụng",
              sortable: true,
              options: {
                filter: "dateTime",
              },
            },
            purchaseCurrency: {
              text: "Loại tiền tệ",
              options: {
                label: true,
                subProp: "purchaseCurrency.name",
              },
            },
            unitPrice: {
              text: "Đơn giá",
              sortable: true,
              options: {
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            costPerUnit: {
              text: "Đơn giá / 1 pcs",
              options: {
                preTransform(_, item) {
                  let value = (item.unitPrice || 0) + (item.localShippingCost || 0);
                  if (item.purchaseCommand) {
                    const totalCount = item.purchaseCommand.items.reduce((total, it) => {
                      return total + it.count;
                    }, 0);
                    let totalShipingCost = item.purchaseCommand.shippingCost;
                    if (item.purchaseCommand.localShippingCostPerItem) {
                      totalShipingCost = item.purchaseCommand.localShippingCostPerItem * (item.purchaseCommand.cbmPerKg || 0) * item.purchaseCommand.exchangeRate;
                      if (item.purchaseCommand.extraCbmPerKg) {
                        totalShipingCost += item.purchaseCommand.extraLocalShippingCostPerItem * (item.purchaseCommand.extraCbmPerKg || 0) * (item.purchaseCommand.extraExchangeRate || item.purchaseCommand.exchangeRate);
                      }
                    }
                    if (item.purchaseCommand.shippingCostItems) {
                      totalShipingCost += item.purchaseCommand.shippingCostItems.reduce((total, it) => {
                        return total + (it.amount || 0) * (it.exchangeRate || 1);
                      }, 0);
                    }
                    const purchaseCommandItem = item.purchaseCommand.items.find((it) => it.productId === item.productId);
                    if (purchaseCommandItem) {
                      value = (purchaseCommandItem.unitPrice || 0) + (totalShipingCost || 0) / totalCount;
                    }
                  }
                  return value;
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            totalPayment: {
              text: "Tổng thanh toán",
              options: {
                preTransform(_, item) {
                  return (item.count || 0) * (item.unitPrice || 0);
                },
                filter: "currency",
                transform(value) {
                  return `${value} VNĐ`;
                },
              },
            },
            warehouse: {
              text: "Kho",
              sortable: true,
              options: {
                subProp: "warehouse.name",
              },
            },
            trackingNumber: {
              text: "Tracking Number",
              sortable: true,
            },
            note: {
              text: "Ghi chú",
            },
            exported: {
              text: "Đã xuất kho",
              sortable: true,
              options: {
                boolean: true,
              },
            },
            action: {},
          },
          filters: {
            filters: {
              _id: {
                attrs: {
                  label: "ID",
                },
                rules: {
                  equal_to_id: {},
                },
              },
              warehouseId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Kho",
                  "item-value": "_id",
                  "item-text": "name",
                  xOptions: {
                    content: {
                      async itemsLoader() {
                        const { items } = await coreApiClient.call("warehouses", "findAll", {
                          payload: JSON.stringify({
                            limit: -1,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              deliveriedTime: {
                type: "XDateTimePicker",
                rules: {
                  greater_than_or_equal_to: {
                    attrs: {
                      label: "Ngày nhập kho (bắt đầu)",
                    },
                  },
                  less_than_or_equal_to: {
                    attrs: {
                      label: "Ngày nhập kho (kết thúc)",
                    },
                  },
                },
              },
              orderId: {
                attrs: {
                  label: "Mã phiếu nhập kho",
                },
                rules: {
                  match: {},
                },
              },
              productId: {
                type: "XAutocomplete",
                attrs: {
                  label: "Sản phẩm",
                  "item-value": "_id",
                  "item-text": "sku",
                  multiple: true,
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("products", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "in",
                                value,
                              },
                            ],
                          }),
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  in: {},
                },
              },
              paymentStatus: {
                type: "select",
                attrs: {
                  label: "Trạng thái thanh toán",
                  multiple: true,
                  items: makeSelectOptions(warehouseImportPaymentStatuses),
                },
                rules: {
                  in: {},
                },
              },
              trackingNumber: {
                attrs: {
                  label: "Tracking number",
                },
                rules: {
                  match: {},
                },
              },
              purchaseCommandId: {
                type: "XAutocomplete",
                attrs: {
                  label: "ID mua hàng",
                  "item-value": "_id",
                  "item-text": "orderIdAlt",
                  xOptions: {
                    content: {
                      async itemsSuggestor(search, value, limit) {
                        const { items } = await coreApiClient.call("purchaseCommands", "findAll", {
                          payload: JSON.stringify({
                            search,
                            limit,
                            filters: [
                              {
                                key: "countryId",
                                operator: "equal_to",
                                value: country._id,
                              },
                              {
                                key: "_id",
                                operator: "equal_to",
                                value,
                              },
                            ],
                          }),
                        });
                        items.forEach((item) => {
                          if (!item.orderIdAlt) {
                            item.orderIdAlt = "[Empty]";
                          }
                        });
                        return items;
                      },
                    },
                  },
                },
                rules: {
                  equal_to: {},
                },
              },
              exported: {
                type: "boolean",
                attrs: {
                  label: "Đã xuất kho",
                },
                rules: {
                  equal_to: {},
                },
              },
            },
          },
          topActionButtons: {
            import: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Nhập Excel",
                icon: "mdi-upload",
              },
              target: {
                tooltip: { content: { text: "Nhập Excel" } },
                dialog: {
                  handlers: {
                    initialize() {
                      this.options.content.buttons.save.states.enabled = new DataContainer(false);
                    },
                  },
                  content: {
                    toolbar: {
                      title: "Nhập Excel",
                    },
                    content: {
                      type: "XForm",
                      makeAttrs(attrs, dialog) {
                        attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                        attrs.xContext = dialog;
                        attrs.xData = new DataContainer({});
                        return attrs;
                      },
                      attrs: {
                        xOptions: {
                          content: {
                            sections: {
                              default: {
                                fields: {
                                  file: {
                                    type: "file",
                                    attrs: {
                                      label: "File",
                                      required: true,
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                    buttons: {
                      save: {
                        content: {
                          text: "Tải lên",
                        },
                        states: {},
                        on: {
                          async xClick({ self }) {
                            const dialog = self.context();
                            const btn = dialog.context();
                            const dataTable = btn.context();
                            const form = dialog.contentContainer.value;
                            const data = form.getData();
                            self.loading.value = true;
                            const result = await dataTable.execute(
                              async () => {
                                return await coreApiClient.call(
                                  "warehouseImports",
                                  "importPrepare",
                                  {
                                    countryId: country._id,
                                  },
                                  data
                                );
                              },
                              undefined,
                              {
                                disableLoading: true,
                                willRefresh: true,
                              }
                            );
                            self.loading.value = false;
                            if (result) {
                              dialog.hide();
                              self2.importItems = result;
                              self2.insertDialogContainer.value.show();
                            }
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            export: userManager.checkRole(["kt", "qlk"]) && {
              attrs: {
                color: "default",
                dark: false,
              },
              content: {
                text: "Xuất Excel",
                icon: "mdi-microsoft-excel",
              },
              on: {
                xClick({ self }) {
                  const dataTable = self.context();
                  let findAllOptions = dataTable.getFindAllOptions();
                  if (dataTable.model.value.length) {
                    findAllOptions = {
                      payload: JSON.stringify({
                        filters: [
                          {
                            key: "_id",
                            operator: "in",
                            value: dataTable.model.value.map((item) => item._id),
                          },
                        ],
                        limit: -1,
                      }),
                    };
                  }
                  coreApiClient.download("warehouseImports", `@/export`, findAllOptions);
                },
              },
            },
            insert: userManager.checkRole(["kt", "qlk"]) && {
              target: {
                dialog: {
                  attrs: {
                    width: "80%",
                  },
                  content: {
                    content: {
                      attrs: {
                        xOptions: {
                          content: {
                            fields: {
                              items: {
                                ext: {
                                  defaultValue() {
                                    return self.importItems;
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                  on: {
                    xHidden() {
                      self.importItems = [];
                    },
                  },
                  ext: {
                    container: self.insertDialogContainer,
                  },
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              edit: userManager.checkRole(["kt", "qlk"]) && {
                target: {
                  dialog: {
                    attrs: {
                      width: "80%",
                    },
                    ext: {
                      subTitleMaker: (item) => item.orderId || (item.product && item.product.sku),
                    },
                  },
                },
              },
              delete: userManager.checkRole(["admin"]) && {},
            },
          },
          findAllOptionsFilter(options) {
            options.filters.push({
              key: "countryId",
              operator: "equal_to",
              value: country._id,
            });
            options.filters.push({
              key: "importType",
              operator: "equal_to",
              value: "nm",
            });
            options.filters.push({
              key: "deliveryStatus",
              operator: "equal_to",
              value: "500",
            });
            return options;
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const { items, count } = await coreApiClient.call("warehouseImports", "findAll", options);
              return [items, count];
            },
            async insert(item) {
              item.countryId = country._id;
              item.importType = "nm";
              return await coreApiClient.call("warehouseImports", "createMany", undefined, item);
            },
            async update(item) {
              return await coreApiClient.call(
                "warehouseImports",
                "update",
                {
                  id: item._id,
                },
                {
                  data: item,
                }
              );
            },
            async delete(item) {
              return await coreApiClient.call("warehouseImports", "delete", {
                id: item._id,
              });
            },
          },
          default: {
            defaultInputs: Object.assign(
              {
                deliveriedTime: {
                  type: "XDateTimePicker",
                  attrs: {
                    label: "Ngày nhập kho",
                    required: true,
                  },
                },
                purchaseCurrencyId: {
                  type: "XAutocomplete",
                  attrs: {
                    label: "Loại tiền tệ",
                    required: true,
                    "item-value": "_id",
                    "item-text": "name",
                    xOptions: {
                      content: {
                        async itemsLoader() {
                          const { items } = await coreApiClient.call("purchaseCurrencies", "findAll", {
                            payload: JSON.stringify({
                              limit: -1,
                              filters: [],
                            }),
                          });
                          return items;
                        },
                      },
                    },
                  },
                },
                exported: {
                  type: "boolean",
                  attrs: {
                    label: "Đã xuất kho",
                  },
                },
                items: {
                  type: "XArrayInput",
                  attrs: {
                    label: "Danh sách đơn hàng",
                    xOptions: {
                      content: {
                        itemLabel: (item) => item.orderId || "[Trống]",
                        itemProperties: Object.assign({}, makeBaseItemProperties(country, true)),
                        template: {
                          formOptions: {
                            content: {
                              colLength: 3,
                            },
                          },
                        },
                      },
                    },
                  },
                  ext: {
                    colLength: 12,
                  },
                },
              },
              makeBaseItemProperties(country)
            ),
            insertForm: userManager.checkRole(["kt", "qlk"]) && {
              content: {
                fieldNames: ["deliveriedTime", "purchaseCommandId", "warehouseId", "purchaseCurrencyId", "items"],
                colLength: 4,
              },
            },
            editForm: userManager.checkRole(["kt", "qlk"]) && {
              content: {
                fieldNames: ["productId", "purchaseCommandId", "warehouseId", "deliveriedTime", "count", "purchaseCurrencyId", "trackingNumber", "manufactureTime", "expirationTime", "paymentStatus", "note", "description", "exported"],
                colLength: 3,
              },
            },
            delete: userManager.checkRole(["admin"]) && {
              confirmDisplayField: "orderId",
            },
          },
        },
      };
    },
  },
  async created() {
    const country = await coreApiClient.call("countries", "findById", {
      id: this.$route.params.countryId,
    });
    this.tableOptions = this.makeTableOptions(country);
  },
});
